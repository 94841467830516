import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogIndex = ({ data }) => {
  const posts = data.allMarkdownRemark.nodes

  return (
    <Layout>
      <SEO />
      <div className="container mx-auto px-4 mt-12">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {posts.map(post => {
            const title = post.frontmatter.title || post.fields.slug

            return (
              <Link to={post.fields.slug} key={post.fields.slug} className="bg-white rounded shadow px-4 py-4 no-underline text-gray-700 hover:opacity-75">
                  <header className="mb-4">
                    <h4 className="mb-0">{title}</h4>
                    <small className="text-gray-500 italic">{post.frontmatter.date}</small>
                  </header>
              </Link>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
